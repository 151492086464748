.value-data{
    color: #7F93B2;
    font-size: 4.5rem;
    font-weight: 700;
}

.label-data{
    color: #26282e;
    font-family: Poppins;
    font-size: 1.125rem;
    font-weight: 500;
}

.data-container{
    width: 13.0625rem;
    height: 7.625rem;
    flex-shrink: 0;
}

.plus-sign{
    color: #7F93B2;
    font-size: 3.125rem;
    font-weight: 700;
    vertical-align: top;
}
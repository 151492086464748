.contact-clients-title{
    color: #26282E;
    text-align: center;
    font-size: 2.875rem;
    font-weight: 700;
}

.contact-clients-title-phone{
    color: #26282E;
    text-align: left;
    font-size: 1.875rem;
    font-weight: 700;
}

.contact-container {
    width: 74.3125rem;
    height: 30.5rem;
    flex-shrink: 0;
}

.contact-info{
    color: #26282E;
    text-align: center;    
    font-size: 1.125rem;    
    font-weight: 400;    
}

.contact-info-phone{
    color: #26282E;
    text-align: center;    
    font-size: 1rem;    
    font-weight: 400;    
}

.contact-info span{
    color: #7F93B2;
    font-weight: 600;
}

.contact-info-phone span{
    color: #7F93B2;
    font-weight: 600;
}

.contact-logos-container{
    width: 20.125rem;
    height: 14.1875rem;
    flex-shrink: 0;
}

.contact-form{
    width: 38.125rem;
    height: 35.1875rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid #B3B3B3;
    padding: 2.5rem;
}

.input-contact-form{
    width: 32.625rem;
    height: 4.375rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid #CCC;
}

.input-contact-form-phone{
    width: 100%;
    height: 3.875rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid #CCC;
}

.btn-contact{
    width: 32.625rem;
    height: 4.3125rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: #26282E;
}

.btn-contact-phone{
    width: 100%;
    height: 3.875rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: #26282E;
}

.btn-contact p {
    color: #FFF;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0;
}

.btn-contact-phone p {
    color: #FFF;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
}

.contact-instruction{
    color: #26282E;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 400;    
}



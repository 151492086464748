.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.title {
  color: #26282E;
  font-size: 3rem;
  font-weight: 700;
  text-align: left;
}

.title-phone {
  color: #26282E;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: left;
}

.subtitle {
  color: #26282E;
  font-size: calc(15px + 2vmin);
  font-weight: 400;
}

.content {
  color: #26282E;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 30px;
}

.content-phone {
  color: #26282E;
  font-size: 0.81rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 30px;
}


.principal-service{
    width: 100%;
    height: 38.625rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
}

.second-service{
    width: 100%;
    height: 18.8125rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
}

.principal-service-img{
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;
}


.services-container{
    height: 15.875rem;
    width: 20rem;
    background-color: #F9F9F9;
}

.services-container-phone{
    height: 10.875rem;
    width: 18rem;
    background-color: #F9F9F9;
}

.services-info-phone {
    color: #26282E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.portfolio-sections{
    width: 100%;
    height: 6.25rem;
    background-color: #F9F9F9;
}

.portfolio-sections-phone{
    width: 100%;
    height: 5.5rem;
    background-color: #F9F9F9;
    overflow-x: scroll;    
}

.portfolio-section {
    width: 5.8125rem;
    height: 100%;    
}

.portfolio-section:hover {
    background-color: rgba(38, 40, 46, 0.5);    
    cursor: pointer;
}


.portfolio-section-active{    
    width: 5.8125rem;
    height: 100%;
    background-color: #262626;
}

.portfolio-section-active-phone{    
    width: 5.8125rem;
    background-color: #262626;
    height: 100%;
}

.portfolio-section-title{
    color: #FFF;
    text-align: center;
    font-size: 0.775rem;    
    font-weight: 600;    
}

.gallery{
    width: 100%;
    height: 34.75rem;
    flex-shrink: 0;    
    background-color: #F9F9F9;
}

.gallery-phone{
    width: 100%;
    height: 40rem;
    flex-shrink: 0;    
}

.gallery-img{
    width: 100%;
    height: 34.75rem;
    flex-shrink: 0;
}

.gallery-img-phone{
    width: 100%;
    height: 27.5rem;
    flex-shrink: 0;
    background-color: #F9F9F9;
}

.container-gallery-phone{
    width: 100%;
    height: 6.5rem;
    background-color: #F9F9F9;
    overflow-x: scroll;
}

.portfolio-img{
    width: 150px;
    height: 150px;
    background-color: rgba(187, 200, 224, 0.5);    
    margin: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
}

.portfolio-img-phone{
    width: 5.625rem;
    height: 5.5rem;
    background-color: rgba(187, 200, 224, 0.5);    
    margin: 4px;        
    display: flex;
    position: relative;     
    overflow: hidden;
}

.portfolio-img .active {
    background-color: rgba(0,0,0,0);
}

.portfolio-img-phone .active {
    background-color: rgba(0,0,0,0);
}

.portfolio-img-prev{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: rgba(187, 200, 224, 0.5);    
    pointer-events: none; /* Allow interaction with the image underneath */
}

.portfolio-img-prev-active{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: rgba(0,0,0,0);    
    pointer-events: none; /* Allow interaction with the image underneath */
}

.portfolio-img-prev-phone{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: rgba(187, 200, 224, 0.5);    
    pointer-events: none; /* Allow interaction with the image underneath */
}

/*.portfolio-img-prev:hover{
    background-color: rgba(187, 200, 224, 0);
    cursor: pointer;
}*/

.portfolio-img:hover{
    background-color: rgba(187, 200, 224, 0);
    cursor: pointer;
}

.portfolio-img-prev-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: rgba(187, 200, 224, 0.5);             
  }

  .portfolio-img-prev-background-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: rgba(0,0,0,0);             
  }

.portfolio-img-prev-background:hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: rgba(187, 200, 224, 0.3);             
}

.portfolio-img-active{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: rgba(187, 200, 224, 0);
}

.gallery-info-phone {
    color: #26282E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
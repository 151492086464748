.footer{
    background-color: #26282E;
    width: 100%;
    height: 8.8125rem;    
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.footer-content{    
    display: flex;
    align-items: center;    
}

.footer-logo{
    width: 6.375rem;
    height: 4.375rem;
    flex-shrink: 0;
}

.footer-content-mt{
    margin-top: 0.7rem;
}

.footer-rrss-content{
    display: flex;
}

.body-text{
    color: #E0E0E0;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 1rem;
}

.body-subtext{
    color: #E0E0E0;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    vertical-align: middle;
}

.copyright-logo{
    width: 0.9375rem;
    height: 0.9375rem;
    flex-shrink: 0;
}

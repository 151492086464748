.welcome {
    width: 100%;
    height: 100vh;
    flex-shrink: 0;    
    position: relative;
}

.welcome-phone {
    width: 100%;
    height: 28rem;
    flex-shrink: 0;   
    position: relative;    
}

.espacio_diaz_img{
    height: 100vh;
}
.welcome-container{
    width: 30rem;
    height: 17.125rem;
    flex-shrink: 0;
    background-color: #26282E;
    padding-left: 2rem;    
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: absolute;
    top: 80%; /* Positions the container 50% from the top of the parent */
    right: 0%; /* Positions the container 50% from the left of the parent */
    transform: translate(0, -80%); /* Centers the container perfectly */
}

.welcome-container-phone{
    width: 21.25rem;
    height: 13.2375rem;
    flex-shrink: 0;
    background-color: #26282E;
    padding-left: 2rem;    
    padding-right: 2rem;
    padding-top: 0.8rem;
    padding-bottom: 2rem;
    position: absolute;
    top: 75%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the container perfectly */
}

.welcome-content-title{
    color: #FFF;    
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0375rem;
    text-align: left;
}

.welcome-content-title-phone{
    color: #FFF;    
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.01875rem;
    text-align: left;
}

.welcome-content-text{
    color: #E1E1E1;    
    font-size: 0.80rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.015rem;
    text-align: left;
}

.welcome-content-text-phone{
    color: #E1E1E1;    
    font-size: 0.72rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.00938rem;
    text-align: left;
}

.explore-btn{
    width: 14.3125rem;
    height: 2.1875rem;
    flex-shrink: 0;
    border: 1px solid #fff;    
    align-content: center;
    padding-left: 1rem;    
    padding-top: 0.2rem;
    
}

.explore-btn-phone{
    width: 11.3125rem;
    height: 1.6875rem;
    flex-shrink: 0;
    border: 1px solid #fff;    
    align-content: center;
    padding-left: 1rem;    
    padding-top: 0.2rem;
    
}

.explore-btn-text{    
    color: #FFF;    
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.015rem;
    text-align: left;    
}

.explore-btn-text-phone{    
    color: #FFF;    
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.015rem;
    text-align: left;    
}

.yellow-dot{
    color: #F3EE85;
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0375rem;
}

.yellow-dot-phone{
    color: #F3EE85;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.01875rem;
}
.rrss-container {
    width: 2.1875rem;
    height: 2.1875rem;    
    background-color: #373A41;
    /*left: 0px; 
    top: 0px; 
    position: absolute;*/
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 8px;
}

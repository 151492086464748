.about-us-index{
    color: #7F93B2;
    font-size: 3rem;
    font-weight: 700;
}

.about-us-title{
    color: #262626;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: left;
}

.about-us-content{
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
}

.divider-phone{
    background-color: #E0E0E0;
    width: 29.3125rem;
    height: 0.0625rem;
}

.about-us-index-phone{
    color: #7F93B2;
    font-size: 2rem;
    font-weight: 700;
    height: 100%;    
    margin-top: 80%;
}

.about-us-title-phone{
    color: #262626;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: left;
}

.about-us-content-phone{
    color: #262626;
    font-size: 0.78rem;
    font-weight: 400;
    text-align: left;
}

.divider-phone{
    background-color: #E0E0E0;
    width: 100%;
    height: 0.0625rem;
}
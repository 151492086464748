.navbar_espacio_diaz{
    background-color: #26282E;
    width: 100%;
    height: 10.5rem;    
    flex-shrink: 0;
    padding: 0.5rem;
}

.navbar-espacio-diaz-phone{
    background-color: #26282E;
    width: 100%;
    height: 5.875rem;
    flex-shrink: 0;
    padding: 0.5rem;
}

.navbar-content {
    color: #D9D9D9 !important;
    font-size: 1rem;
    font-weight: 400;
}


.navbar-info-text{
    color: #E0E0E0 !important;
    font-size: 0.75rem;
    font-weight: 300;
}

.logo-phone{
    width: 5.125rem;
    height: 3.4875rem;
    flex-shrink: 0;
}

.logo-phone-container{
    margin-left: 50%;
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 0.05rem;
}

.nav-item{    
    color: #26282E !important;
    
}